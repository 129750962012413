export const DATE_FORMAT = 'YYYY-MM-DD';
export const PRETTY_DATE_FORMAT = 'MMMM Do[,] YYYY';
export const TIME_FORMAT_24 = 'HH:mma';
export const TIME_FORMAT_12 = 'h:mma';
export const VUETIFY_DATE_PICKER_FORMAT = 'YYYY-MM-DD';

export const INPUT_DATE_FORMAT = 'YYYY/MM/DD';
export const PRETTY_SMALL_DATE_FORMAT = 'MMM Do';
export const PRETTY_SMALL_DATE_FORMAT_WITH_YEAR = 'MMM Do YYYY';
export const TIME_FORMAT = 'HH:mm';
export const TWELVE_HOUR_TIME_FORMAT = 'hh:mm A';
export const PRETTY_FULL_DATE = `${PRETTY_DATE_FORMAT} ${TIME_FORMAT}`;
export const VUETIFY_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
