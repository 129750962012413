<template>
  <v-menu
    ref="dialog"
    v-if="$vuetify.breakpoint.mdAndUp"
    v-model="dialog"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
    :disabled="disableMenu"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="selectedDate"
        :placeholder="placeholder"
        :error-messages="errors"
        prepend-inner-icon="mdi-calendar"
        readonly
        outlined
        v-bind="attrs"
        v-on="on"
        :disabled="disabled"
        :label="label"
        data-cy="datepicker--input"
        :dense="dense"
        :hide-details="hideDetails"
      >
        <template v-slot:append>
          <slot name="append" />
        </template>
      </v-text-field>
    </template>
    <v-date-picker
      ref="picker"
      :header-color="pickerColor"
      color="accent"
      :max="max"
      :min="min"
      @click:date="dialog = false"
      :activePicker="activePicker"
      v-model="selectedDate"
      :picker-date.sync="pickerDate"
      :data-jest="dataAttribute"
      :type="month ? 'month' : 'date'"
      @change="dialog = false"
      data-cy="datepicker--select"
    >
    </v-date-picker>
  </v-menu>

  <div v-else>
    <v-dialog ref="dialog" v-model="dialog" :disabled="disableMenu">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :value="selectedDate"
          :error-messages="errors"
          prepend-inner-icon="mdi-calendar"
          readonly
          :label="placeholder"
          outlined
          v-bind="attrs"
          v-on="on"
          :disabled="disabled"
          data-cy="datepicker--input"
          :dense="dense"
          :hide-details="hideDetails"
        >
          <template v-slot:append>
            <slot name="append" />
          </template>
        </v-text-field>
      </template>
      <v-date-picker
        ref="picker"
        :header-color="pickerColor"
        color="accent"
        :max="max"
        :min="min"
        @click:date="dialog = false"
        full-width
        :activePicker="activePicker"
        v-model="selectedDate"
        :picker-date.sync="pickerDate"
        :data-jest="dataAttribute"
        :type="month ? 'month' : 'date'"
        @change="dialog = false"
        data-cy="datepicker--select"
      >
      </v-date-picker>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'DatePicker',

  props: {
    date: {
      type: [String, Number],
      default: '',
    },
    label: String,
    pickerColor: {
      type: String,
      default: 'secondary',
    },
    placeholder: String,
    errors: Array,
    max: String,
    min: String,
    disabled: Boolean,
    dataAttribute: String,
    month: Boolean,
    outlined: Boolean,
    dense: Boolean,
    hideDetails: Boolean,
    disableMenu: Boolean,
  },
  data() {
    return {
      activePicker: 'YEAR',
      dialog: false,
      defaultDate: '1990-01-01',
      pickerDate: null,
    };
  },
  methods: {
    async scrollToYear(attempt = 0) {
      if (attempt > 10) return;
      const scrollableElement = document.querySelector('.v-date-picker-years');
      // Do not auto-scroll if user has already scrolled
      if (scrollableElement?.scrollTop) return;
      const { top: parentTop } = scrollableElement?.getBoundingClientRect() || {};
      const targetElement = scrollableElement?.querySelector('.accent--text');
      const { top: childTop } = targetElement?.getBoundingClientRect() || {};
      if (!childTop || !parentTop || childTop === parentTop) {
        setTimeout(() => {
          this.scrollToYear(attempt + 1);
        }, 100);
      } else {
        scrollableElement.scrollTop = childTop - parentTop;
      }
    },
  },
  computed: {
    selectedDate: {
      get() {
        if (!this.date) return this.date;
        return this.$store.getters.toUTC(this.date).format(this.month ? 'YYYY-MM' : 'YYYY-MM-DD');
      },
      set(value) {
        this.$emit('updateDate', value);
      },
    },
    tenantUrl() {
      return this.$store.state.registration.tenantUrl;
    },
  },
  created() {
    this.pickerDate = new Date(this.defaultDate).toISOString().substr(0, 10);
  },
  watch: {
    dialog(val) {
      if (val) {
        setTimeout(() => {
          this.scrollToYear();
        }, 200);

        this.activePicker = null;
        this.$nextTick(() => {
          this.activePicker = 'YEAR';
        });
      }
    },
  },
};
</script>
