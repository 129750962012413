<template>
  <div class="address-input__wrapper">
    <input
      id="address-search"
      class="map-search"
      :class="inputClasses"
      :placeholder="placeholder"
      type="text"
      ref="search"
      v-model="autocompleteValue.formattedAddress"
      @change="updateAddress"
      @blur="onBlur"
    />

    <div class="address-errors__wrapper" v-if="!hideDetails">
      <v-slide-y-transition>
        <span v-if="errors">
          {{ errors }}
        </span>
      </v-slide-y-transition>
    </div>
  </div>
</template>
<style lang="scss">
.address-input__wrapper {
  .address-errors__wrapper {
    font-size: 12px;
    line-height: 12px;
    color: var(--v-error-base);
    padding-left: 12px;
    margin-bottom: 8px;
    min-height: 14px;
    bottom: 0;
    color: red;
    span {
      white-space: pre;
    }
  }
}
.pac-container {
  z-index: 2005;
}
</style>
<script>
import { GOOGLE_API_KEY } from '../constants/env';

const defaultAddress = {
  city: '',
  country: 'Canada',
  postalCode: '',
  province: '',
  streetName: '',
  streetNumber: '',
  suite: '',
  poBox: '',
};
/* eslint-disable max-len */
const scriptSrc = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places`;

export default {
  name: 'AddressInput',
  data() {
    return {
      formattedAddress: null,
      service: null,
    };
  },

  async mounted() {
    this.getGoogleApi();
    setTimeout(() => {
      this.mapsInit();
    }, 1000);
  },
  computed: {
    apiKey() {
      return GOOGLE_API_KEY;
    },
    autocompleteValue: {
      deep: true,
      get() {
        return this.value;
      },
      set(value) {
        if (!value.formattedAddress) {
          this.$emit('input', {
            ...defaultAddress,
          });
        } else {
          this.$emit('input', value);
        }
      },
    },
    providerId() {
      const { providerId } = this.$store.getters['patientPortal/currentUser'];
      return providerId;
    },
  },
  methods: {
    getGoogleApi() {
      const scriptExists = document.querySelector(`script[src='${scriptSrc}']`);
      if (scriptExists) return;
      const scriptTag = document.createElement('script');
      scriptTag.setAttribute('src', scriptSrc);
      document.head.appendChild(scriptTag);
    },
    mapsInit() {
      this.service = new window.google.maps.places.Autocomplete(this.$refs.search, {
        componentRestrictions: { country: ['ca'] },
        fields: ['address_components', 'geometry', 'formatted_address'],
        types: ['address'],
      });
      this.service.addListener('place_changed', () => {
        this.selectLocation();
      });
    },
    selectLocation() {
      const newAddress = { ...defaultAddress, poBox: this.value.poBox };
      const place = this.service.getPlace();
      const { formatted_address: formattedAddress } = place;
      if (this.formattedAddress !== formattedAddress) this.formattedAddress = formattedAddress;
      this.prioritizeAddressComponents([...place.address_components]).forEach((component) => {
        const componentType = component.types[0];

        const addressComponentForField = {
          subpremise: 'suite',
          street_number: 'streetNumber',
          route: 'streetName',
          postal_code: 'postalCode',
          postal_code_prefix: 'postalCode',
          locality: 'city',
          sublocality_level_1: 'city',
          sublocality_level_2: 'city',
          sublocality_level_3: 'city',
          administrative_area_level_2: 'city',
          administrative_area_level_3: 'city',
          administrative_area_level_1: 'province',
          country: 'country',
        };
        const field = addressComponentForField[componentType];
        if (!field) return;
        if (newAddress[field]) return;
        if (component.long_name === 'Québec') {
          newAddress.province = 'Quebec';
        } else {
          newAddress[field] = component.long_name;
        }
      });
      if (!newAddress.streetNumber) {
        const streetNumber = this.$refs.search.value.match(/^(\w*)/)[0];
        if (streetNumber) newAddress.streetNumber = streetNumber;
      }
      this.autocompleteValue = {
        ...newAddress,
        formattedAddress,
      };
    },
    // If user clicks in and away from empty address input, should see error
    onBlur() {
      this.$emit('validate');
      if (!this.autocompleteValue.formattedAddress) this.$emit('input', this.autocompleteValue);
    },
    prioritizeAddressComponents(components) {
      const priority = {
        subpremise: 1,
        street_number: 1,
        route: 1,
        postal_code: 1,
        postal_code_prefix: 2,
        locality: 1,
        sublocality_level_1: 2,
        sublocality_level_2: 3,
        sublocality_level_3: 4,
        administrative_area_level_2: 5,
        administrative_area_level_3: 6,
        administrative_area_level_1: 7,
        country: 8,
      };
      return components.sort((a, b) => priority[a.types[0]] - priority[b.types[0]]);
    },
    // Reset address object if user manually clears address input
    updateAddress(value) {
      this.$emit('validate');
      if (!value.target.value) {
        this.autocompleteValue = {
          ...defaultAddress,
        };
      }
    },
  },
  props: {
    errors: String,
    hideDetails: Boolean,
    inputClasses: String,
    placeholder: {
      type: String,
      default() {
        return this.$t('addressInput.placeholder');
      },
    },
    value: {
      default() {
        return { ...defaultAddress };
      },
      type: Object,
    },
  },
};
</script>
