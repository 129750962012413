/* eslint-disable no-unused-expressions */
import i18n from '../plugins/i18n';

export const PAGE_SIZE_OPTIONS = [10, 20, 30, 40, 50];
export const SESSION_PROVIDER_ID = 'providerId';

export const VALIDATION_ERRORS = {
  REQUIRED: i18n.t('registration.errors.validation.required'),
  NOT_VALID_EMAIL: i18n.t('registration.errors.validation.invalidEmail'),
  ALPHA_ONLY: i18n.t('registration.errors.validation.alphaOnly'),
  NUMBERS_ONLY: i18n.t('registration.errors.validation.numbersOnly'),
  MUST_AGREE: i18n.t('registration.errors.validation.mustAgree'),
  INVALID_HEALTHCARD: i18n.t('registration.errors.validation.invalidHealthcard'),
};

export const VUELIDATE_ERRORS = {
  computed: {
    additionalCommentsErrors() {
      const errors = [];
      if (!this.$v.additionalComments.$dirty) return errors;
      !this.$v.additionalComments.maxLength &&
        errors.push('Please limit your comments to 100 characters');

      return errors;
    },
    cityErrors() {
      const errors = [];
      if (!this.$v.patientData.mailingAddress.city.$dirty) return errors;
      !this.$v.patientData.mailingAddress.city.required && errors.push(VALIDATION_ERRORS.REQUIRED);

      return errors;
    },
    countryErrors() {
      const errors = [];
      if (!this.$v.patientData.mailingAddress.country.$dirty) return errors;

      !this.$v.patientData.mailingAddress.country.required &&
        errors.push(VALIDATION_ERRORS.REQUIRED);
      !this.$v.patientData.mailingAddress.country.maxLength &&
        errors.push('Country name can not exceed 56 characters');
      return errors;
    },
    firstNameErrors() {
      const errors = [];
      if (!this.$v.patientData.firstName.$dirty) return errors;
      !this.$v.patientData.firstName.nameValidator && errors.push(VALIDATION_ERRORS.ALPHA_ONLY);
      !this.$v.patientData.firstName.required && errors.push(VALIDATION_ERRORS.REQUIRED);
      !this.$v.patientData.firstName.maxLength &&
        errors.push(i18n.t('registration.registerIntroduce.errors.firstNameErrors.maxLength'));
      !this.$v.patientData.firstName.minLength &&
        errors.push(i18n.t('registration.registerIntroduce.errors.firstNameErrors.minLength'));
      return errors;
    },
    lastNameErrors() {
      const errors = [];
      if (!this.$v.patientData.lastName.$dirty) return errors;
      !this.$v.patientData.lastName.nameValidator && errors.push(VALIDATION_ERRORS.ALPHA_ONLY);
      !this.$v.patientData.lastName.required && errors.push(VALIDATION_ERRORS.REQUIRED);
      !this.$v.patientData.lastName.maxLength &&
        errors.push(i18n.t('registration.registerIntroduce.errors.lastNameErrors.maxLength'));
      !this.$v.patientData.lastName.minLength &&
        errors.push(i18n.t('registration.registerIntroduce.errors.lastNameErrors.minLength'));
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.patientData.email.$dirty) return errors;
      !this.$v.patientData.email.email && errors.push(VALIDATION_ERRORS.NOT_VALID_EMAIL);
      !this.$v.patientData.email.required && errors.push(VALIDATION_ERRORS.REQUIRED);
      !this.$v.patientData.email.maxLength && errors.push('Email can not be over 256 characters');
      return errors;
    },
    signUpEmailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push(VALIDATION_ERRORS.NOT_VALID_EMAIL);
      !this.$v.email.required && errors.push(VALIDATION_ERRORS.REQUIRED);
      !this.$v.email.maxLength && errors.push('Email can not be over 256 characters');
      !this.$v.email.uniqueEmail && errors.push('Email already in use');
      return errors;
    },
    consentErrors() {
      const errors = [];
      if (!this.$v.consent.$dirty) return errors;
      !this.$v.consent.checked && errors.push(VALIDATION_ERRORS.MUST_AGREE);
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.patientData.phone.$dirty) return errors;
      !this.$v.patientData.phone.primary.required && errors.push(VALIDATION_ERRORS.REQUIRED);
      !this.$v.patientData.phone.primary.phone &&
        errors.push(i18n.t('registration.errors.validation.invalidPhone'));
      return errors;
    },
    postalCodeErrors() {
      const errors = [];
      if (!this.$v.patientData.mailingAddress.postalCode.$dirty) return errors;
      !this.$v.patientData.mailingAddress.postalCode.required &&
        errors.push(VALIDATION_ERRORS.REQUIRED);

      return errors;
    },
    prescriptionSelectionErrors() {
      const errors = [];
      if (!this.$v.prescriptionSelection.$dirty) return errors;
      !this.$v.prescriptionSelection.required && errors.push(VALIDATION_ERRORS.REQUIRED);
      return errors;
    },
    provinceErrors() {
      const errors = [];
      if (!this.$v.patientData.mailingAddress.province.$dirty) return errors;
      !this.$v.patientData.mailingAddress.province.required &&
        errors.push(VALIDATION_ERRORS.REQUIRED);
      return errors;
    },
    healthCardNumErrors() {
      const errors = [];
      if (!this.$v.patientData.healthCard.number.$dirty) return errors;
      !this.$v.patientData.healthCard.number.required && errors.push(VALIDATION_ERRORS.REQUIRED);
      !this.$v.patientData.healthCard.number.numeric && errors.push(VALIDATION_ERRORS.NUMBERS_ONLY);
      !this.$v.patientData.healthCard.number.validateHin &&
        errors.push(VALIDATION_ERRORS.INVALID_HEALTHCARD);
      return errors;
    },
    healthCardVerErrors() {
      const errors = [];
      if (!this.$v.patientData.healthCard.versionNumber.$dirty) return errors;

      !this.$v.patientData.healthCard.versionNumber.alpha &&
        errors.push(VALIDATION_ERRORS.ALPHA_ONLY);
      !this.$v.patientData.healthCard.versionNumber.minLength &&
        errors.push(i18n.t('registration.errors.validation.minLength', { num: 2 }));
      !this.$v.patientData.healthCard.versionNumber.maxLength &&
        errors.push(i18n.t('registration.errors.validation.maxLength', { num: 3 }));
      return errors;
    },
    healthCardExpiryErrors() {
      const errors = [];
      if (!this.$v.patientData.healthCard.expiry.$dirty) return errors;

      !this.$v.patientData.healthCard.expiry.healthCardDate &&
        errors.push(i18n.t('registration.errors.validation.invalidDate'));
      return errors;
    },
    dateOfBirthErrors() {
      const errors = [];
      if (!this.$v.patientData.dateOfBirth.$dirty) return errors;
      !this.$v.patientData.dateOfBirth.required && errors.push(VALIDATION_ERRORS.REQUIRED);
      return errors;
    },
    reasonErrors() {
      const errors = [];
      if (!this.$v.appointmentDetails.reasonForVisit.$dirty) return errors;
      !this.$v.appointmentDetails.reasonForVisit.required &&
        errors.push(VALIDATION_ERRORS.REQUIRED);
      return errors;
    },
    allergyErrors() {
      const errors = [];
      if (!this.$v.appointmentDetails.listOfAllergies.$dirty) return errors;
      !this.$v.appointmentDetails.listOfAllergies.required &&
        errors.push(VALIDATION_ERRORS.REQUIRED);
      !this.$v.appointmentDetails.listOfAllergies.maxLength &&
        errors.push('Field can not exceed 1024 characters');
      return errors;
    },
    medicationErrors() {
      const errors = [];
      if (!this.$v.appointmentDetails.listOfMedications.$dirty) return errors;
      !this.$v.appointmentDetails.listOfMedications.required &&
        errors.push(VALIDATION_ERRORS.REQUIRED);
      !this.$v.appointmentDetails.listOfMedications.maxLength &&
        errors.push('Field can not exceed 1024 characters');
      return errors;
    },
    otherReasonForVisitErrors() {
      const errors = [];
      if (!this.$v.otherReasonForVisit.$dirty) return errors;
      !this.$v.otherReasonForVisit.required &&
        errors.push('Please enter a reason for the appointment');
      !this.$v.otherReasonForVisit.minLength &&
        errors.push('Please enter a reason for the appointment');
      !this.$v.otherReasonForVisit.maxLength &&
        errors.push("Appointment reason shouldn't exceed 90 characters in length");

      return errors;
    },
    reasonForVisitErrors() {
      const errors = [];
      if (!this.$v.reasonForVisit.$dirty) return errors;
      !this.$v.reasonForVisit.required && errors.push('Please enter a reason for the appointment');
      !this.$v.reasonForVisit.minLength && errors.push('Please enter a reason for the appointment');
      !this.$v.reasonForVisit.maxLength &&
        errors.push("Appointment reason shouldn't exceed 90 characters in length");

      return errors;
    },
    streetNameErrors() {
      const errors = [];
      if (!this.$v.patientData.mailingAddress.streetName.$dirty) return errors;
      !this.$v.patientData.mailingAddress.streetName.required &&
        errors.push(VALIDATION_ERRORS.REQUIRED);
      !this.$v.patientData.mailingAddress.streetName.maxLength &&
        errors.push('Street name must not exceed 100 characters');

      return errors;
    },
    streetNumberErrors() {
      const errors = [];
      if (!this.$v.patientData.mailingAddress.streetNumber.$dirty) return errors;
      !this.$v.patientData.mailingAddress.streetNumber.required &&
        errors.push(VALIDATION_ERRORS.REQUIRED);
      !this.$v.patientData.mailingAddress.streetNumber.maxLength && errors.push('Max 7 digits');

      return errors;
    },
  },
};
