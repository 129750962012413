export default `
SENTRY HEALTH INC.
(the “Company”)

Personal Health Information Consent Form
(“Consent Form”)

This form addresses the collection, use and disclosure of personal health information under the Personal Health Information Protection Act, 2004 (the “PHIPA”).

Privacy of your personal information is important to us.The Company understands the importance of protecting your personal information that may consist of:

<ol type="a">
<li>History and physical examinations;</li>
<li>Consultation reports;</li>
<li>Laboratory reports;</li>
<li>Operative reports;</li>
<li>Discharge summary;</li>
<li>X-ray/Diagnostic images;</li>
<li>Bioelectric output (i.e., EKG, EEG); and</li>
<li>Tissue and/or blood specimens; (collectively “<b>Personal Health Information</b>”).</li>
</ol>
To help you understand how we are protecting your Personal Health Information, we have outlined below how our office is using and disclosing your information. Further information about your rights and how we protect and maintain your Personal Health Information can be found by accessing our privacy policy (the “<b>Privacy Policy</b>”).

The Company will collect, use and disclose Personal Health Information about you for the following purposes (collectively the “<b>Purpose</b>”):

<ol type="a">
<li>provide services to our Health Care Providers (as defined in the Privacy Policy);</li>
<li>provide customer service and support, administrative messages, resolve disputes, and troubleshoot problems including helping third-party service providers fulfill their functions;</li>
<li>fulfill your requests for certain features of the Product and Services (as defined in the Privacy Policy;</li>
<li>customize, measure and improve the Product and Services;</li>
<li>offer or provide you with products or services including information related to receipts, technical notices, updates and security alerts; </li>
<li>measure our performance and to share performance information with others; </li>
<li>comply with legal or regulatory requirements (as described below); and </li>
<li>fulfill other purposes, subject to your explicit consent </li></ol>
Your Personal Health Information shall be disclosed to only those who have a need to know and the specific information disclosed shall be restricted to only that information relevant to the recipients. Those who have a need to know include, but are not limited to, health care providers, pharmacists, radiology centres, and medical laboratories.

The Company is committed to collecting, using and disclosing your Personal Health Information responsibly. The Company also tries to be as open and transparent as possible about the way the Company handles your Personal Health Information and the Company will act in pursuant to the PHIPA.

By signing the consent section of this Consent Form, you have agreed that you have given your informed consent to the collection, use and/or disclosure of your Personal Health Information. If a new purpose arises for the use and/or disclosure of your Personal Health Information, we will seek your approval in advance.

We will destroy information in a secure manner when the information is no longer necessary for the provision of our services and is not required to be retained for compliance with provincial or federal regulations or statutes.

We are committed to providing you with open access to your Personal Health Information. You may at any time ask us to see your records held by us and to request amendments to that information. We will provide access to you within a reasonable time.

Should you wish to make a formal complaint regarding our privacy practices, or wish to view your information, please do so in writing to our Privacy Officer, by email at privacy@sentryhealth.ca.


<i>I (“<b>User</b>”) hereby give my consent to disclose my Personal Health Information to the Company for the “Purpose”.</i>
`;
