import provinces from '../constants/provinces';
/* eslint-disable no-param-reassign */
/* eslint-disable no-eval */
export const mod10CheckCalDig = (dig) => {
  let ret = `${dig * 2}`;
  if (ret.length === 2) {
    ret = eval(ret.charAt(0) * 1 + ret.charAt(1) * 1);
  }

  return ret;
};

export const mod10Check = (hinNum) => {
  let typeInOK = false;
  const hChar = [];
  let sum = 0;
  for (let i = 0; i < hinNum.length; i += 1) {
    hChar[i] = hinNum.charAt(i);
  }

  for (let i = 0; i < hinNum.length; i += 2) {
    hChar[i] = mod10CheckCalDig(hChar[i]);
  }

  for (let i = 0; i < hinNum.length - 1; i += 1) {
    sum = eval(sum * 1 + hChar[i] * 1);
  }

  const calDigit = 10 - `${sum}`.charAt(`${sum}`.length - 1);
  if (hChar[hinNum.length - 1] === `${calDigit}`.charAt(`${calDigit}`.length - 1)) typeInOK = true;

  return typeInOK;
};

export const checkTypeNum = (typeIn) => {
  let typeInOK = true;
  let i = 0;
  const { length } = typeIn;
  let ch;
  // walk through a string and find a number
  if (length >= 1) {
    while (i < length) {
      ch = typeIn.substring(i, i + 1);
      if (ch === '-') {
        i += 1;
        // eslint-disable-next-line no-continue
        continue;
      }
      if (ch < '0' || ch > '9') {
        typeInOK = false;
        break;
      }
      i += 1;
    }
  } else typeInOK = false;
  return typeInOK;
};

export const isValidHin = (hin) => {
  const parsedHin = hin.replace(/\W/gi, '').replace(/\D/g, '');

  if (parsedHin.length !== 10) return false;
  if (!checkTypeNum(parsedHin)) return false;

  return mod10Check(parsedHin);
};

export const validateHcFormat = function (number, province) {
  if (!number) return false;
  // HIN checksum disabled on certain tenant flows (06/18/2021: demo only)
  // HIN only enabled on certain envs (06/18/2021: production only)
  return number.length === provinces.find((p) => province === p.key).format.length;
};

export const formatHin = (hin) => {
  if (hin.length === 10) {
    hin = hin.split('');
    hin.splice(4, 0, '-');
    hin.splice(8, 0, '-');
    hin = hin.join('');
  }
  return hin;
};

export const validateHin = (number, province, checkSum = false) => {
  if (!number) return false;
  if (!checkSum) {
    return number.length === provinces.find((p) => province === p.key).format.length;
  }
  return isValidHin(number);
};
