<template>
  <v-sheet color="background" elevation="9">
    <v-form class="health-card health-card-desktop d-flex flex-column">
      <div class="province-select__wrap pa-1 d-flex">
        <div style="font-size: 0.8em; color: white; margin-top: 6px" class="mr-2">
          {{ $t('main.province') }}:
        </div>
        <v-autocomplete
          :items="provinceOptions"
          dense
          hide-details
          v-model="healthCard.province"
          class="pb-3 province-select__select"
          dark
        />
      </div>
      <!-- Row one (number and version code) -->
      <div class="d-flex">
        <v-text-field
          :class="tenantUrl + '-theme-text-field'"
          v-model="healthCard.number"
          :error-messages="healthCardNumErrors"
          v-mask="hcMask"
          background-color="white"
          outlined
          dense
          :placeholder="hcMask"
          @change="$v.healthCard.number.$touch()"
          @blur="$v.healthCard.number.$touch()"
          data-jest="health-card__input--number"
          return-masked-input
        ></v-text-field>

        <div
          style="width: 100px"
          class="ml-2"
          v-if="provinceHasVersionNumber && !this.healthCardVersionDisabled"
        >
          <v-text-field
            :class="tenantUrl + '-theme-text-field'"
            v-model="healthCard.versionNumber"
            background-color="white"
            v-mask="'AA'"
            outlined
            dense
            :placeholder="$t('registration.registerIntroduce.versionCode')"
            :disabled="healthCardVersionDisabled"
            data-jest="health-card__input--version"
          ></v-text-field>
        </div>
      </div>
      <div
        class="d-flex justify-end"
        :class="{ 'flex-row-reverse': $vuetify.breakpoint.mdAndDown }"
        :style="{ visibility: this.healthCardExpiryDisabled ? 'hidden' : 'visible' }"
      >
        <v-tooltip top :disabled="!healthCardExpired">
          <template v-slot:activator="{ on, attrs }">
            <div
              class="mt-3"
              :class="$vuetify.breakpoint.mdAndDown ? 'ml-2' : 'mr-3'"
              v-on="on"
              v-bind="attrs"
              style="height: 74px; width: 24px"
            >
              <v-icon color="error" class="shake" v-if="healthCardExpired">mdi-alert</v-icon>
            </div>
          </template>
          <span>{{ $t('registration.registerIntroduce.healthCardExpired') }} </span>
        </v-tooltip>
        <div style="max-width: 300px">
          <date-picker
            :date="healthCard.expiry"
            :errors="healthCardExpiryErrors"
            :placeholder="$t('registration.registerIntroduce.expiry')"
            :min="expiryMin"
            :max="expiryMax"
            @updateDate="(date) => updateExpiry(date)"
            :disabled="healthCardExpiryDisabled"
            dataAttribute="health-card-input__expiry"
            :month="isMonthExpiry"
          ></date-picker>
        </div>
      </div>
    </v-form>
  </v-sheet>
</template>
<style lang="scss">
.province-select__wrap {
  position: absolute;
  right: 0;
  top: 0;
  max-width: 305px;
}
.health-card {
  position: relative;
  border: 1px solid var(--v-secondary-base);
  border-radius: 8px !important;
  width: 100%;
}
.health-card::before {
  content: '';
  position: absolute;
  height: 50px;
  width: 100%;
  border-radius: 8px 8px 0px 0px;
  top: 0;
  left: 0;
  background-color: var(--v-secondary-base);
}
.health-card::after {
  content: '';
  position: absolute;
  height: 50px;
  width: 100%;
  border-radius: 0px 0px 8px 8px;
  bottom: 0;
  left: 0;
  background-color: var(--v-secondary-base);
}
.health-card-desktop {
  padding: 65px 12px 38px 12px;
}
</style>
<script>
import { requiredIf, minLength, maxLength, alpha } from 'vuelidate/lib/validators';
import { NODE_ENV } from '../constants/env';
import { DISABLE_CHECKSUM_TENANTURL, ENABLE_CHECKSUM_ENV } from '../constants/feature-flag';
import { validateHin } from '../utils/healthcard';
import { VALIDATION_ERRORS } from '../constants/validation';
import DatePicker from './DatePicker.vue';
import DATE_FORMAT from '../constants/moment';
import provinces from '../constants/provinces';

export default {
  name: 'HealthCardInput',
  data() {
    return {};
  },
  props: {
    healthCardNumberOptional: Boolean,
    healthCardExpiryDisabled: Boolean,
    healthCardExpiryOptional: Boolean,
    healthCardVersionDisabled: Boolean,
    healthCardVersionOptional: Boolean,
    value: Object,
  },
  components: {
    DatePicker,
  },
  created() {
    this.setProvince();
  },
  computed: {
    expiry() {
      return this.healthCard.expiry;
    },
    expiryMax() {
      return this.$store.getters.toTimezone().add(10, 'years').format(DATE_FORMAT);
    },
    expiryMin() {
      return this.$store.getters.toTimezone().subtract(10, 'years').format(DATE_FORMAT);
    },
    hcMask() {
      return provinces.find((p) => p.key === this.province).format;
    },
    healthCard: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    healthCardNumErrors() {
      const errors = [];
      if (!this.$v.healthCard.number.$dirty) return errors;
      if (!this.$v.healthCard.number.required) errors.push(VALIDATION_ERRORS.REQUIRED);
      if (!this.$v.healthCard.number.validateHin) errors.push(VALIDATION_ERRORS.INVALID_HEALTHCARD);
      return errors;
    },
    healthCardExpired() {
      const { expiry } = this.value;
      if (!expiry) return false;
      return (
        this.$store.getters.toTimezone().unix() > this.$store.getters.toTimezone(expiry).unix()
      );
    },
    healthCardExpiryErrors() {
      const errors = [];
      if (!this.$v.healthCard.expiry.$dirty) return errors;

      if (!this.$v.healthCard.expiry.required) errors.push('Please input valid date');
      return errors;
    },
    isMonthExpiry() {
      return provinces.find((p) => this.province === p.key).expiryFormat === 'month';
    },
    province() {
      return this.value.province;
    },
    provinceOptions() {
      return provinces.map((p) => {
        return {
          text: this.$t(`provinces.${p.key}`),
          value: p.key,
        };
      });
    },
    provinceHasVersionNumber() {
      const versionNumProvinces = ['ontario'];
      return versionNumProvinces.includes(this.province);
    },
    tenantUrl() {
      return this.$store.state.registration.tenantUrl;
    },
  },
  methods: {
    setProvince() {
      let province = 'ontario';
      const { province: selectedProvince } =
        this.$store.state.registration?.patientData?.healthCard || {};
      if (selectedProvince) {
        province = selectedProvince;
        return;
      }
      const { lang } = this.$route.query || {};

      if (lang === 'fr') province = 'quebec';
      this.healthCard.province = province;
    },
    updateExpiry(date) {
      this.healthCard.expiry = date;
      this.$v.healthCard.expiry.$touch();
      this.$v.healthCard.expiry.$touch();
    },
  },
  watch: {
    expiry(val) {
      const split = val.split('-');
      if (split.length === 3) return;
      this.healthCard.expiry = this.$store.getters
        .toTimezone(val)
        .endOf('month')
        .format('YYYY-MM-DD');
    },
  },
  validations() {
    return {
      healthCard: {
        number: {
          required: requiredIf(() => !this.healthCardNumberOptional),
          validateHin: (val) => {
            if (this.healthCardNumberOptional) return true;
            const { tenantUrl } = this.$store.state.registration;
            const { province } = this;
            const checkLengthOnly =
              DISABLE_CHECKSUM_TENANTURL.includes(tenantUrl) ||
              !ENABLE_CHECKSUM_ENV.includes(NODE_ENV) ||
              province !== 'ontario';

            return validateHin(val, province, !checkLengthOnly);
          },
        },
        versionNumber: {
          alpha,
          minLength: minLength(2),
          maxLength: maxLength(2),
          required: requiredIf(
            () =>
              !this.healthCardVersionOptional &&
              !this.healthCardVersionDisabled &&
              this.provinceHasVersionNumber &&
              (NODE_ENV === 'qa' || NODE_ENV === 'production')
          ),
        },
        expiry: {
          required: requiredIf(
            () =>
              !this.healthCardExpiryOptional &&
              !this.healthCardExpiryDisabled &&
              (NODE_ENV === 'qa' || NODE_ENV === 'production')
          ),
        },
      },
    };
  },
};
</script>
