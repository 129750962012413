<template>
  <register-container>
    <template slot="header"
      >{{ pageTitle || $t('registration.registerIntroduce.title') }}{{ disclaimer ? '*' : '' }}
    </template>
    <template slot="subtitle">{{ pageSubtitle }} </template>
    <v-row v-if="disclaimer" class="mb-0 mb-md-4 mt-0" :class="{ 'px-6': returning }">
      <v-col class="d-flex justify-center py-0"
        ><div
          style="font-size: 0.7em; font-style: italic; text-align: center"
          :style="{ maxWidth: returning ? '700px' : '' }"
        >
          {{ '* ' }} {{ disclaimer }}
        </div></v-col
      >
    </v-row>
    <v-row :class="oneRow ? 'flex-column align-center' : 'flex-row'" class="mb-1 mb-md-0">
      <!-- Desktop Form -->
      <v-col
        cols="12"
        :sm="oneRow ? 8 : 12"
        :md="oneRow ? 0 : 6"
        class="pa-3 pa-md-1 pr-md-2"
        :class="this.oneRow ? 'order-2 pt-0' : 'order-0 pb-0'"
      >
        <div :class="this.$vuetify.breakpoint.mdAndUp ? 'desktop-form-container' : ''">
          <v-form
            class="d-flex flex-column justify-space-between"
            :class="this.$vuetify.breakpoint.mdAndUp ? 'desktop-form' : ''"
          >
            <v-text-field
              v-if="showFirstName"
              :class="this.tenantUrl + '-theme-text-field'"
              :placeholder="$t('main.firstName')"
              outlined
              :error-messages="firstNameErrors"
              v-model="patientData.firstName"
              @change="$v.patientData.firstName.$touch()"
              @blur="$v.patientData.firstName.$touch()"
            ></v-text-field>
            <v-text-field
              v-if="showPreferredName"
              :class="this.tenantUrl + '-theme-text-field'"
              :placeholder="$t('main.preferredName')"
              outlined
              v-model="patientData.preferredName"
            ></v-text-field>
            <v-text-field
              v-if="showLastName"
              :class="this.tenantUrl + '-theme-text-field'"
              :placeholder="$t('main.lastName')"
              outlined
              :error-messages="lastNameErrors"
              v-model="patientData.lastName"
              @change="$v.patientData.lastName.$touch()"
              @blur="$v.patientData.lastName.$touch()"
            ></v-text-field>

            <date-picker
              v-if="showDob"
              :date="patientData.dateOfBirth"
              :placeholder="$t('main.dob')"
              :errors="dateOfBirthErrors"
              :max="now"
              :min="'1901-01-01'"
              @updateDate="(date) => updateDOB(date)"
            ></date-picker>

            <v-text-field
              v-if="showPhone"
              :class="this.tenantUrl + '-theme-text-field'"
              :placeholder="`${$t('main.phoneNumber')} ${
                isPhoneOptional ? `(${$t('registration.tags.optional')})` : ''
              }`"
              v-mask="'###-###-####'"
              :error-messages="phoneErrors"
              outlined
              v-model="patientData.phone.primary"
              @change="$v.patientData.phone.primary.$touch()"
              @blur="$v.patientData.phone.primary.$touch()"
              data-cy="phone-input"
            ></v-text-field>
            <v-text-field
              v-if="showEmail"
              :class="this.tenantUrl + '-theme-text-field'"
              :placeholder="`${$t('main.email')} ${
                isEmailOptional ? `(${$t('registration.tags.optional')})` : ''
              }`"
              :error-messages="emailErrors"
              outlined
              v-model="patientData.email"
              @change="$v.patientData.email.$touch()"
              @blur="$v.patientData.email.$touch()"
            ></v-text-field>
            <v-row>
              <v-col :cols="showPoBox ? 9 : 12">
                <address-input
                  v-if="showAddress"
                  v-model="patientData.mailingAddress"
                  inputClasses="text-field-input v-input__slot v-input theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined v-text-field--placeholder demo-theme-text-field"
                  :placeholder="`${$t('main.mailingAddress')} ${
                    isAddressOptional ? `(${$t('registration.tags.optional')})` : ''
                  }`"
                  :errors="mailingAddressErrors"
                  @input="onAddressChange"
                  @validate="$v.patientData.mailingAddress.$touch()"
                >
                </address-input>
              </v-col>
              <v-col cols="3" class="pl-1" v-if="showPoBox">
                <v-text-field
                  outlined
                  placeholder="PO Box"
                  v-model="patientData.mailingAddress.poBox"
                />
              </v-col>
            </v-row>
          </v-form>
        </div>
      </v-col>
      <v-col
        cols="12"
        :sm="12"
        :md="oneRow ? 12 : 6"
        class="health-container d-flex flex-column align-center"
        :class="
          oneRow
            ? 'mt-0 mt-sm-3 mb-3 d-flex justify-center'
            : 'mt-0 pt-0 py-sm-0 justify-space-between'
        "
        :style="{
          marginBottom: !oneRow && this.$vuetify.breakpoint.mdAndUp ? rightRowMarginBottom : '0px',
        }"
      >
        <!-- Insurance / Health Card -->
        <div
          style="width: inherit"
          class="form-wrapper__container"
          :class="oneRow && 'd-flex justify-center'"
        >
          <p role="label" class="mt-0 mb-2 h6-text" v-if="!returning">
            {{ $t('registration.registerIntroduce.enterHealthCard') }}
          </p>

          <div class="form-wrapper d-flex justify-center">
            <health-card-input
              v-model="patientData.healthCard"
              :healthCardVersionDisabled="healthCardVersionDisabled"
              :healthCardExpiryDisabled="healthCardExpiryDisabled"
              :healthCardExpiryOptional="healthCardExpiryOptional"
              :healthCardVersionOptional="healthCardVersionOptional"
              :healthCardNumberOptional="healthCardNumberOptional"
              ref="healthCardInput"
            />
          </div>
        </div>

        <!-- Gender Buttons -->
        <div class="d-flex flex-column" style="width: 500px; max-width: 100%" v-if="showGender">
          <div class="mb-2 h6-text">{{ $t('registration.registerIntroduce.gender') }}</div>
          <div class="d-flex justify-space-between">
            <hover-button
              :classes="'mr-1'"
              @click="patientData.gender = MALE"
              :selected="patientData.gender === MALE"
              >{{ $t('main.gender.male') }}</hover-button
            >
            <hover-button
              :classes="'ml-1'"
              @click="patientData.gender = FEMALE"
              :selected="patientData.gender === FEMALE"
              >{{ $t('main.gender.female') }}</hover-button
            >
          </div>
        </div>
        <div style="width: 100%" class="mt-2" v-if="showPreferredPronoun">
          <div class="mb-2 h6-text">{{ $t('main.preferredPronoun') }}</div>
          <v-select outlined hide-details :items="pronouns" v-model="patientData.preferredPronoun">
            <template v-slot:item="{ item }">{{ $t(`main.gender.pronouns.${item}`) }}</template>
            <template v-slot:selection="{ item }">{{
              $t(`main.gender.pronouns.${item}`)
            }}</template>
          </v-select>
        </div>
      </v-col>
    </v-row>

    <v-row class="mt-0 mb-0">
      <v-col class="py-0 d-flex justify-center" v-if="showConsent">
        <v-checkbox
          class="mt-md-0 pl-sm-0 pl-4"
          v-model="consent"
          :error-messages="consentErrors"
          @change="$v.consent.$touch()"
          @blur="$v.consent.$touch()"
        >
          <div slot="label">
            {{ $t('registration.registerIntroduce.consent.readAndAgreed') }}
            <info-dialog :dialogContent="consentForm">
              <template slot="open-dialog"
                ><a href="/tos">{{
                  $t('registration.registerIntroduce.consent.title')
                }}</a></template
              >

              <template slot="dialog-title">{{
                $t('registration.registerIntroduce.consent.title')
              }}</template>
            </info-dialog>
          </div>
        </v-checkbox>
      </v-col>
    </v-row>
  </register-container>
</template>
<style lang="scss">
.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-3px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(3px, 0, 0);
  }
}
.text-field-input {
  padding-left: 12px !important;
  height: 55px !important;
  border-collapse: collapse;
  border-color: rgba(0, 0, 0, 0.38);
  border-style: solid;
  border-width: 1px;
  transition-duration: 0.3s;
  transition-property: color, border-width;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  margin-bottom: 8px !important;
  &::placeholder {
    color: rgba(0, 0, 0, 0.3);
  }
  &:focus {
    border-color: black;
    outline: none;
  }
}

.desktop-form {
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .v-input {
    align-items: center !important;
  }
}
.desktop-form-container {
  height: 100%;
}
.patient-info-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 3rem;
}
.form-wrapper {
  width: 100%;
  background-color: var(--v-background);
  border-radius: 8px;
  margin-bottom: 13px;
  .v-sheet {
    width: 100% !important;
  }
}
.form-wrapper__container {
  max-width: 500px;
}

.gender-button {
  border: 1px solid var(--v-secondary-base);
  background-color: white !important;
  border-radius: 8px !important;
  height: 53px !important;
  font-size: 1em;
}
@media all and (min-width: 768px) {
  .form-wrapper {
    max-height: 300px;
  }
}
@media all and (max-width: 768px) {
  .health-card-3 {
    grid-column: 1/4;
    grid-row: 2/3;
  }
}
</style>

<script>
import { requiredIf, maxLength, email, helpers, minLength } from 'vuelidate/lib/validators';
import moment from 'moment';
import { VUELIDATE_ERRORS } from '../constants/validation';
import { InsuranceType, insuranceOptions } from '../constants/insurance';
import { MALE, FEMALE } from '../constants/gender';
import RegisterContainer from '../components/RegisterContainer.vue';
import HoverButton from '../components/HoverButton.vue';
import DatePicker from '../components/DatePicker.vue';
import DATE_FORMAT from '../constants/moment';
import { phone } from '../utils/validation';
import consentForm from '../constants/legal';
import InfoDialog from '../components/InfoDialog.vue';
import AddressInput from '../components/AddressInput.vue';
import HealthCardInput from '../components/HealthCardInput.vue';

const nameValidator = helpers.regex('nameValidator', /^[a-zA-Z\-\s]+$/);

// Custom Validators

export default {
  name: 'RegisterIntroduce',
  mixins: [VUELIDATE_ERRORS],
  components: {
    AddressInput,
    DatePicker,
    HealthCardInput,
    HoverButton,
    InfoDialog,
    RegisterContainer,
  },
  data() {
    return {
      /* tenant customizable options */
      checkUserAttempts: 0,
      currentAttempt: 0,
      consent: false,
      consentForm,
      isAddressOptional: true,
      isEmailOptional: false,
      isPhoneOptional: false,
      menu: false,
      pronouns: ['him', 'her', 'they', 'ze', 'other', 'none', 'all'],
      dialog: false,
      disclaimer: false,
      expiryMenu: false,
      expiryDialog: false,
      InsuranceType,
      insuranceOptions,
      patientData: null,
      genderHover: null,
      /* show/hide fields */
      MALE,
      FEMALE,
      checkForExistingUser: true,
      healthCardVersionDisabled: false,
      healthCardVersionOptional: false,
      healthCardExpiryDisabled: false,
      healthCardExpiryOptional: false,
      healthCardNumberOptional: false,
      showAddress: false,
      showConsent: true,
      showEmail: true,
      showGender: true,

      showFirstName: true,
      showLastName: true,
      showPreferredName: false,
      showPreferredPronoun: false,
      showDob: true,
      showPhone: true,
      showPoBox: false,
      oneRow: false,
      returning: false,
    };
  },
  computed: {
    // Looks for the 'config' key under the pageflow,
    // trt2.config in ../constants/pageflows.js
    // and then under config, finds 'RegisterIntroduce' (name of current component)
    config() {
      // this.$options.name == name of component (RegisterIntroduce)
      return this.$store.getters.getConfig(this.$options.name);
    },

    completed() {
      return !this.$v.$invalid && !this.$refs?.healthCardInput?.$v?.$invalid;
    },

    healthCardExpired() {
      const { expiry } = this.patientData?.healthCard;
      if (!expiry) return false;
      return (
        this.$store.getters.toTimezone().unix() > this.$store.getters.toTimezone(expiry).unix()
      );
    },
    mailingAddressErrors() {
      let missingValues = [];
      const { $params: params } = this.$v.patientData.mailingAddress;
      Object.keys(params).forEach((param) => {
        if (
          !this.$v.patientData.mailingAddress[param].$dirty ||
          !this.$v.patientData.mailingAddress[param].$invalid
        )
          return;
        missingValues.push(param);
      });
      missingValues = missingValues
        .map((val) => this.$t(`registration.registerIntroduce.errors.addressErrors.${val}`))
        .join(', ');
      if (!missingValues.length) return '';
      return `${this.$t('registration.registerIntroduce.errors.addressErrors.text', {
        fields: missingValues,
      })}\n ${this.$t('registration.registerIntroduce.errors.addressErrors.tip')}`;
    },
    optionalEmail() {
      return this.isEmailOptional;
    },
    now() {
      return moment().format(DATE_FORMAT);
    },
    numRows() {
      return [
        this.showAddress,
        this.showEmail,
        this.showFirstName,
        this.showLastName,
        this.showDob,
        this.showPhone,
      ].filter((i) => !!i).length;
    },
    remainingFields() {
      return [
        {
          name: 'firstName',
          displayText: this.$t('registration.errors.missingField', {
            field: this.$t('main.firstName'),
          }),
          valid: !this.$v.patientData.firstName.$invalid,
        },
        {
          name: 'lastName',
          displayText: this.$t('registration.errors.missingField', {
            field: this.$t('main.lastName'),
          }),
          valid: !this.$v.patientData.lastName.$invalid,
        },
        {
          name: 'dateOfBirth',
          displayText: this.$t('registration.errors.missingField', {
            field: this.$t('main.dob'),
          }),
          valid: !this.$v.patientData.dateOfBirth.$invalid,
        },
        {
          name: 'phone',
          displayText: this.$t('registration.errors.missingField', {
            field: this.$t('main.phoneNumber'),
          }),
          valid: !this.$v.patientData.phone.primary.$invalid,
        },
        {
          name: 'email',
          displayText: this.$t('registration.errors.missingField', {
            field: this.$t('main.email'),
          }),
          valid: !this.$v.patientData.email.$invalid,
        },
        {
          name: 'gender',
          displayText: this.$t('registration.errors.missingField', {
            field: this.$t('registration.registerIntroduce.gender'),
          }),
          valid: !this.$v.patientData.gender.$invalid,
        },
        {
          name: 'hcn',
          displayText: this.$t('registration.errors.missingField', {
            field: this.$t('registration.registerIntroduce.healthCardNumber'),
          }),
          valid: !this.$refs?.healthCardInput?.$v?.healthCard?.number?.$invalid,
        },
        {
          name: 'versionCode',
          displayText: this.$t('registration.errors.missingField', {
            field: this.$t('registration.registerIntroduce.versionCode'),
          }),
          valid: !this.$refs?.healthCardInput?.$v?.healthCard?.versionNumber?.$invalid,
        },
        {
          name: 'hcExpiry',
          displayText: this.$t('registration.errors.missingField', {
            field: this.$t('registration.registerIntroduce.expiry'),
          }),
          valid: !this.$refs?.healthCardInput?.$v?.healthCard?.expiry?.$invalid,
        },
        {
          name: 'consent',
          displayText: this.$t('registration.errors.missingField', {
            field: this.$t('registration.registerIntroduce.termsOfService'),
          }),
          valid: !this.$v.consent.$invalid,
        },
        {
          name: 'mailingAddress',
          displayText: this.$t('registration.registerIntroduce.errors.addressErrors.missingText'),
          valid: !this.$v.patientData.mailingAddress.$invalid,
        },
      ];
    },
    rightRowMarginBottom() {
      return {
        4: '49px',
        5: '36px',
        6: '34px',
      }[this.numRows];
    },
    tenantUrl() {
      return this.$store.state.registration.tenantUrl;
    },
  },
  created() {
    this.getDataFromStore();
  },
  methods: {
    onAddressChange(val) {
      this.patientData.mailingAddress = val;
      this.patientData.billingAddress = val;
      this.$v.patientData.mailingAddress.$touch();
    },
    getDataFromStore() {
      this.patientData = { ...this.$store.state.registration.patientData };
      if (!this.patientData.notes?.other?.length) return;
      const { other } = this.patientData.notes;
      this.consent = other.some((note) => note.noteTags.includes('Consent'));
    },
    getInitialState() {
      const { config } = this;
      if (!config) return;
      Object.keys(this.config).forEach((option) => {
        if (typeof config[option] === 'object') {
          Object.assign(this[option], config[option]);
        } else {
          this[option] = config[option];
        }
      });
    },
    async onNextClick() {
      if (this.patientData.preferredName || this.patientData.preferredPronoun) {
        this.$store.commit('addOtherPatientNote', {
          newNoteText: `Patient preferred name: ${
            this.patientData.preferredName || 'Unspecified'
          } \n Patient preferred pronouns: ${
            this.$t(`main.gender.pronouns.${this.patientData.preferredPronoun}`) || 'Unspecified'
          }`,
          noteTags: ['Gender', 'Pinned'],
        });
      }
      await this.$store.commit('setPatientData', {
        ...this.patientData,
        healthCard: {
          ...this.patientData.healthCard,
          number: this.patientData?.healthCard?.number?.replaceAll('-', '').replaceAll(' ', ''),
        },
        pharmacy: this.$store.state.registration.patientData.pharmacy,
        email: this.patientData.email || null,
        billingAddress: { ...this.patientData.mailingAddress },
      });

      if (this.checkForExistingUser) {
        this.$store.commit('setCompleted', false);
        await this.$store.commit('setNextButtonText', 'isLoading');
        const { userId: memberId } = (await this.$store.dispatch('checkForExistingUser')) || {};
        if (!memberId && this.returning) {
          this.$store.commit('setNotification', {
            color: 'error',
            text: 'Could not find user.',
            showing: true,
            timeout: 3000,
          });
        }
        await this.$store.commit('setRegistration', { memberId, isReturningPatient: !!memberId });
      }
      await this.$store.commit('setNextButtonText', null);
      if (
        this.checkForExistingUser &&
        this.currentAttempt < this.checkUserAttempts &&
        !this.$store.state.registration.memberId
      ) {
        this.currentAttempt += 1;
        this.$store.commit('setCompleted', true);
        return;
      }
      // clear notification
      this.$store.commit('setNotification', {
        color: 'error',
        text: '',
        showing: false,
        timeout: 3000,
      });
      if (this.showConsent) {
        await this.$store.commit('addOtherPatientNote', {
          newNoteText: `User has read & accepted the terms of service`,
          noteTags: ['Consent'],
        });
      }

      if (this.config?.onNext) this.config.onNext();
      this.$store.commit('setCompleted', false);
      /*
        TODO: eliminate isPageFlowV2 check once we have
        fully migrated over to pageflow 2.0 system
      */
      if (this.isPageflowV2) {
        this.$emit('next');
      } else {
        this.$router.push({ name: this.$store.getters.nextFlowRoute(this.$route.name) });
      }
    },
    updateDOB(date) {
      this.patientData.dateOfBirth = date;
      this.$v.patientData.dateOfBirth.$touch();
      this.$v.patientData.dateOfBirth.$touch();
    },
    updateExpiry(date) {
      this.patientData.healthCard.expiry = date;
      this.$v.patientData.healthCard.expiry.$touch();
      this.$v.patientData.healthCard.expiry.$touch();
    },
  },
  mounted() {
    this.currentAttempt = 0;
    if (!this.isPageflowV2) {
      this.$store.commit('setCompleted', this.completed);
    }

    this.$store.commit('setOnNextClick', this.onNextClick);
    /*
        TODO: eliminate isPageFlowV2 check once we have
        fully migrated over to pageflow 2.0 system
      */
    // getInitialState() is handled via Vue mixin for PF 2.0
    if (!this.isPageflowV2) {
      this.$nextTick(() => {
        this.getInitialState();
      });
    }
  },
  validations() {
    return {
      consent: {
        checked: (value) => !this.showConsent || value === true,
      },
      patientData: {
        firstName: {
          required: requiredIf(() => this.showFirstName),
          nameValidator,
          minLength: minLength(2),
          maxLength: maxLength(100),
        },
        lastName: {
          required: requiredIf(() => this.showLastName),
          nameValidator,
          minLength: minLength(2),
          maxLength: maxLength(100),
        },
        dateOfBirth: {
          required: requiredIf(() => this.showDob),
        },
        phone: {
          primary: {
            required: requiredIf(() => this.showPhone && !this.isPhoneOptional),
            phone,
          },
        },
        email: {
          required: requiredIf(() => !this.isEmailOptional),
          email,
          maxLength: maxLength(256),
        },
        gender: {
          required: requiredIf(() => this.showGender),
        },

        mailingAddress: {
          streetNumber: {
            required: requiredIf(
              () => !this.isAddressOptional || this.patientData?.mailingAddress.formattedAddress
            ),
          },
          streetName: {
            required: requiredIf(
              () => !this.isAddressOptional || this.patientData?.mailingAddress.formattedAddress
            ),
          },
          city: {
            required: requiredIf(
              () => !this.isAddressOptional || this.patientData?.mailingAddress.formattedAddress
            ),
          },
          country: {
            required: requiredIf(
              () => !this.isAddressOptional || this.patientData?.mailingAddress.formattedAddress
            ),
          },
          province: {
            required: requiredIf(
              () => !this.isAddressOptional || this.patientData?.mailingAddress.formattedAddress
            ),
          },
          postalCode: {
            required: requiredIf(
              () => !this.isAddressOptional || this.patientData?.mailingAddress.formattedAddress
            ),
          },
        },
      },
    };
  },
  watch: {
    completed(val) {
      this.$store.commit('setCompleted', val);
    },
    config(val) {
      /*
        TODO: eliminate isPageFlowV2 check once we have
        fully migrated over to pageflow 2.0 system
      */
      if (val && !this.isPageflowV2) this.getInitialState();
    },
  },
};
</script>
