export const InsuranceType = {
  None: {
    key: 'none',
    label: 'None, I am paying',
  },
  Public: {
    key: 'public',
    label: 'Public Insurance',
  },
  Private: {
    key: 'private',
    label: 'Private Insurance',
  },
};

export const insuranceOptions = [InsuranceType.Public, InsuranceType.Private, InsuranceType.None];
