<template>
  <v-dialog v-model="dialog" max-width="800px">
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on"> <slot name="open-dialog"></slot></span>
    </template>
    <v-card>
      <v-card-title class="d-flex justify-space-between flex-nowrap align-start mb-2"
        ><div class="text-break">
          <slot name="dialog-title"></slot>
        </div>
        <div>
          <v-icon class="close-tos" @click="dialog = false">mdi-close</v-icon>
        </div></v-card-title
      >
      <v-card-text class="terms-of-service" v-html="dialogContent"> </v-card-text>
    </v-card>
  </v-dialog>
</template>
<style lang="scss">
.terms-of-service {
  max-height: 500px;
  overflow-y: auto;
  white-space: pre-wrap !important;
  ol {
    display: flex;
    flex-direction: column;
  }
}
</style>

<script>
export default {
  name: 'InfoDialog',
  data() {
    return {
      dialog: false,
    };
  },
  props: {
    dialogContent: {
      required: true,
      type: String,
    },
  },
};
</script>
